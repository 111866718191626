import { useEffect, useRef } from 'react';

export const useZoomToElement = (
	clipRoot: HTMLElement | null,
	focusedRegion: string | null,
	zoomContainer: HTMLElement | null,
) => {
	const effectTracker = useRef({ hasEverRun: false });

	useEffect(() => {
		if (!zoomContainer || !clipRoot) {
			return;
		}

		const moveable = zoomContainer.querySelector('.moveable')! as SVGElement;
		const zoomTarget =
			(zoomContainer.querySelector(`.region-${focusedRegion}`) as SVGElement) || undefined;

		const zoomPadding = 30;

		const doIt = () => {
			// zoom out to reset all the scaling transformations
			zoomToContain(clipRoot, moveable, undefined, zoomPadding);
			// zoom into the new region
			zoomToContain(clipRoot, moveable, zoomTarget, zoomPadding);
		};

		if (!effectTracker.current.hasEverRun) {
			effectTracker.current.hasEverRun = true;
			doIt();
			return;
		}

		moveable
			.animate([{ opacity: 0 }], { duration: 500, fill: 'forwards' })
			.addEventListener('finish', () => {
				doIt();
				// fade in to reveal the new region
				moveable.animate([{ opacity: 1 }], { duration: 500, fill: 'forwards' });
			});

		// zoomToContain(moveable, zoomTarget);
	}, [focusedRegion, zoomContainer]);
};

const zoomToContain = (
	clipRoot: HTMLElement,
	haystack: HTMLElement | SVGElement,
	needle?: HTMLElement | SVGElement,
	padding: number = 0,
) => {
	if (!needle) {
		haystack.style.transform = 'scale(1) translateX(0) translateY(0)';
		return;
	}

	const haystackRect = haystack.getBoundingClientRect();
	let needleRect = needle.getBoundingClientRect();
	const clipRect = clipRoot.getBoundingClientRect();

	const haystackCenter = {
		x: haystackRect.x + haystackRect.width / 2,
		y: haystackRect.y + haystackRect.height / 2,
	};

	const needleCenter = {
		x: needleRect.x + needleRect.width / 2,
		y: needleRect.y + needleRect.height / 2,
	};

	let zoomAmountRequired = Math.min(
		haystackRect.width / needleRect.width,
		haystackRect.height / needleRect.height,
	);

	// Adjust zoom amount to include padding
	const paddingFactor =
		(Math.max(haystackRect.width, haystackRect.height) - padding) /
		Math.max(haystackRect.width, haystackRect.height);
	zoomAmountRequired *= paddingFactor;

	let yPos = haystackCenter.y - needleCenter.y;
	let xPos = haystackCenter.x - needleCenter.x;

	let transformation = [
		`scale(${zoomAmountRequired})`,
		`translateX(${xPos}px)`,
		`translateY(${yPos}px)`,
	].join(' ');

	haystack.style.transform = transformation;
};
